<template>
  <div class="skill-groups">
    <!-- 查询条件 -->
    <el-form :model="formTable.form" class="form-style">
      <el-row :gutter="32" type="flex">
        <el-col :span="6">
          <div class="form-item">
            <span class="form-item-label">技能组</span>
            <el-input v-model="formTable.form.keyword" size="small" placeholder="请输入技能组名称">
            </el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-item-handle">
            <el-button type="primary" size="small" @click="clickSubmit" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="clickReset" icon="el-icon-refresh-left">
              重置
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <!-- 添加 -->
    <el-button
      type="primary"
      size="small"
      class="add-btn"
      icon="el-icon-plus"
      @click="clickDialogAdd('add')"
    >
      添加
    </el-button>

    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      v-loading="loading"
      :header-cell-style="headerCellStyle"
      element-loading-text="拼命加载中"
    >
      <el-table-column prop="skillGroups" label="技能组名" min-width="100"></el-table-column>
      <el-table-column prop="updateTime" label="最后更新时间" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.updateTime ? scope.row.updateTime : scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="最后操作人" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.updateUserName ? scope.row.updateUserName : scope.row.createUserName }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="platformUserNum" label="关联坐席" min-width="100">
        <template slot-scope="scope">
          <span
            :class="scope.row.platformUserNum === '0' ? 'scope-router-no' : 'scope-router'"
            @click.stop="gotoDepartment(scope.row, 'platformUserIds')"
          >
            99返回对应数量的platformUserId{{ scope.row.platformUserNum }}
          </span>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" min-width="100" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row, 'edit')">编辑</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 翻页 -->
    <Pages
      :total="formTable.page.total"
      :current-page="formTable.page.pageNumber"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    />

    <!-- 添加-弹窗 -->
    <el-dialog
      :title="dialogAddOpen === 'edit' ? '编辑' : '添加'"
      :visible.sync="dialogAdd"
      width="28%"
    >
      <el-form
        label-position="right"
        label-width="100px"
        :model="formTable.form"
        @submit.native.prevent
      >
        <el-form-item label="技能组名称">
          <el-input
            class="diy-flex-input"
            v-model="formTable.form.skillGroups"
            placeholder="请输入技能组名称"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clickCancul()">取 消</el-button>
        <el-button
          type="primary"
          @click.stop="dialogAddOpen === 'edit' ? clickHandleEdit() : clickAdd()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPageList, add, deleteById, updateById, getById } from '@/api/call.js'
import Pages from '@/components/pages'

export default {
  components: {
    Pages,
  },
  data() {
    return {
      formTable: {
        form: {
          callAppConfigId: '2',
          keyword: '',
          skillGroups: '',
          skillGroupsId: '',
        },
        page: {
          pageNumber: 1,
          pageSize: 10,
          total: 10,
        },
      },
      headerCellStyle: {
        color: '#909399',
        background: '#F5F7FA',
      },
      tableData: [],
      loading: true,
      dialogAdd: false,
      dialogAddOpen: '',
    }
  },
  created() {
    this.getPageListApi()
  },
  methods: {
    // 列表
    async getPageListApi() {
      try {
        this.loading = true
        const data = {
          pageNumber: this.formTable.page.pageNumber,
          pageSize: this.formTable.page.pageSize,
          data: this.formTable.form,
        }
        const res = await getPageList(data)
        if (res.data) {
          const { content, total } = res.data
          this.formTable.page.total = +total
          this.tableData = content
          this.loading = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 翻页
    handleCurrentChange(val) {
      this.formTable.page.pageNumber = val
      this.getPageListApi()
    },
    handleSizeChange(val) {
      this.formTable.page.pageSize = val
      this.getPageListApi()
    },
    // 查询
    clickSubmit() {
      this.formTable.page = this.$options.data().formTable.page
      this.getPageListApi()
    },
    // 重置
    clickReset() {
      this.formTable = this.$options.data().formTable
      this.getPageListApi()
    },
    // 添加-弹窗
    clickDialogAdd(type) {
      this.dialogAdd = true
      this.dialogAddOpen = type
    },
    // 添加-确定
    async clickAdd() {
      if (this.formTable.form.skillGroups === '') {
        this.$message.error('请输入技能组名称')
        return
      }
      this.formTable.form.keyword = ''
      try {
        const data = {
          data: this.formTable.form,
        }
        const res = await add(data)
        if (res.success) {
          this.clickReset()
          this.dialogAdd = false
          this.$message({
            message: '添加成功',
            type: 'success',
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 取消
    clickCancul() {
      this.formTable.form = this.$options.data().formTable.form
      this.dialogAdd = false
    },
    // 编辑-弹窗
    async handleEdit(vals, type) {
      this.dialogAdd = true
      this.dialogAddOpen = type
      try {
        const data = {
          data: vals.skillGroupsId,
        }
        const res = await getById(data)
        if (res.success) {
          this.formTable.form.skillGroups = res.data.skillGroups
          this.formTable.form.skillGroupsId = res.data.skillGroupsId
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 编辑-确定
    async clickHandleEdit() {
      if (this.formTable.form.skillGroups === '') {
        this.$message.error('请输入技能组名称')
        return
      }
      this.formTable.form.keyword = ''
      try {
        const data = {
          data: this.formTable.form,
        }
        const res = await updateById(data)
        if (res.success) {
          this.clickCancul()
          this.getPageListApi()
          this.$message({
            message: '编辑成功',
            type: 'success',
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 删除
    handleDelete(vals) {
      const message = '确定要删除吗？'
      this.$confirm(message, '确定提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await deleteById({ data: vals.skillGroupsId })
          if (res.success) {
            this.getPageListApi()
            this.$message({
              message: '删除成功',
              type: 'success',
            })
          }
        })
        .catch(() => {})
    },
    // // 跳转-部门管理
    // gotoDepartment(row, type) {
    //   console.log(row, '[]跳转[]', type)

    //   const platformUserIds = JSON.stringify('4593')
    //   localStorage.setItem('platformUserIds', platformUserIds)
    //   const newUrl = `${window.location.origin}/index/system/org/department`
    //   window.open(newUrl)
    // },
  },
}
</script>

<style lang="scss" scoped>
.skill-groups {
  padding: 20px;
  background-color: #ffffff;

  .form-style {
    .form-item {
      display: flex;
      align-items: center;
      background: #f5f6f9;
      border-radius: 4px 4px 4px 4px;
      padding: 0 12px;
      height: 38px;
      margin-bottom: 20px;
      .form-item-label {
        font-size: 14px;
        color: #999999;
        line-height: 32px;
        margin-right: 24px;
        white-space: nowrap;
      }
      :deep(.el-input__inner) {
        border: none;
        background: #f5f6f9;
        font-size: 14px;
        padding: 0 0 0 20px;
      }
    }

    .form-item-handle {
      height: 38px;
      display: flex;
      align-items: center;
    }
  }

  .scope-router {
    cursor: pointer;
    color: #2861e2;
    padding: 0 10px 10px 0;
  }
  .scope-router-no {
    cursor: default;
    color: #b5b6b6;
  }

  .add-btn {
    margin-bottom: 20px;
  }
}
</style>
